















.layout--full-page {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.square {
  height: 100%;
}
